<template>
  <div class="vue-facture-obligee">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          size="sm"
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.stop.prevent="handleClickVueFacture"
        >
          <font-awesome-icon icon="eye" />
        </div>
      </template>
      <span>Vue Facture</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      max-width="1800px"
      height="86%"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-header-vue-oblige"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"> Reglement Factures obligés</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text">
          <div
            class="box-all-filter reg-oblige"
            v-if="checkPermission('APTHAR') && computedCheckedRowsOneClient"
          >
            <div class="box-end-position">
              <reglement
                v-if="checkPermission('APTHAR') && computedCheckedRowsOneClient"
                :dataToUse="computedCheckedRowsOneClientFacture"
                :societeRequired="computedUserData"
                interfaceModal="ReglementFactureRowOblige"
                interfaceName="oblige"
                @updateDataInParentComponentReglement="
                  updateDataInParentComponentReglement
                "
              />
            </div>
          </div>
          <div v-if="loaderData == true" class="loading-custom-reglement-block">
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#704ad1'"
              class="loeder"
            />
          </div>
          <v-data-table
            :headers="computedDFields"
            :items="dataOblige"
            :scrollable="false"
            hide-default-footer
            hide-action
            disable-filtering
            disable-sort
            disable-pagination
            :fixed-header="true"
            class="table-dialog"
            :class="{
              'show-btn':
                checkPermission('APTHAR') && computedCheckedRowsOneClient
            }"
            item-class="custom-cell"
            no-data-text="Il n'y a aucun enregistrement à afficher"
          >
            <template v-slot:[`header.check_all`]="{ header }">
              <td class="custom-cell">
                <span v-if="header.value != 'check_all'">
                  {{ header.text }}</span
                >
                <div v-else>
                  <v-checkbox
                    class="checked-global mt-1"
                    v-model="checkAll"
                    @change="checkAllFunction"
                    color="#704ad1"
                    :value="true"
                    :unchecked-value="false"
                    hide-details
                  ></v-checkbox>
                </div>
              </td>
            </template>
            <template v-slot:[`item.check_all`]="{ item }">
              <td class="custom-cell">
                <v-checkbox
                  class="checked-global mt-1"
                  v-model="item.check"
                  color="#704ad1"
                  :value="true"
                  :unchecked-value="false"
                  hide-details
                ></v-checkbox>
              </td>
            </template>
            <template v-slot:[`item.vendeur`]="{ item }">
              <td class="custom-cell">{{ item.vendeur }}</td>
            </template>
            <template v-slot:[`item.lot`]="{ item }">
              <td class="custom-cell">{{ item.lot }}</td>
            </template>
            <template v-slot:[`item.client`]="{ item }">
              <td class="custom-cell">{{ item.client }}</td>
            </template>
            <template v-slot:[`item.numero_facture`]="{ item }">
              <td class="custom-cell">{{ item.num }}</td>
            </template>
            <template v-slot:[`item.ht`]="{ item }">
              <td class="custom-cell">{{ item.total_ht }}</td>
            </template>
            <template v-slot:[`item.tva`]="{ item }">
              <td class="custom-cell">{{ item.total_tva }}</td>
            </template>
            <template v-slot:[`item.ttc`]="{ item }">
              <td class="custom-cell">{{ item.total_ttc }}</td>
            </template>
            <template v-slot:[`item.reste`]="{ item }">
              <td class="custom-cell">{{ item.reste }} €</td>
            </template>
            <template v-slot:[`item.etat`]="{ item }">
              <td class="custom-cell">{{ item.etat }}</td>
            </template>
            <template v-slot:[`item.statut_client`]="{ item }">
              <td class="custom-cell">{{ item.statut_client }}</td>
            </template>
            <template v-slot:[`item.date_paiement`]="{ item }">
              <td class="custom-cell">{{ item.date_paiement }}</td>
            </template>
            <template v-slot:[`item.reglement`]="{ item }">
              <td class="custom-cell">{{ item.montant_regle }}€</td>
            </template>
            <template v-slot:cell(reglement)="data">
              <td>
                {{ data.item.montant_regle }} €
                <PopoverReglementFacture
                  v-show="item && item.reglements && item.reglements.length"
                  :factureReglement="item.reglements"
                  :permissionModifier="false"
                  dataInterface="oblige"
                >
                </PopoverReglementFacture>
              </td>
            </template>
          </v-data-table>
        </v-card-text>

        <!-- <v-divider class="line-hr-footer"></v-divider> -->
        <v-card-actions class="actions-msg-btn-modal">
          <div class="footer-style-table">
            <div
              v-if="
                computedTotal &&
                  (computedTotal.ht > 0 ||
                    computedTotal.ttc > 0 ||
                    computedTotal.someApaye > 0)
              "
              class="style-somme-th-ttc pl-2"
            >
              <div>
                <span class="bold-text"> Total ht:</span>
                {{ '   ' }}
                {{   computedTotal.ht
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '), }}
                € {{ '   ' }}

                <span class="bold-text"> Total ttc:</span>
                {{ '   ' }}

                {{
                  computedTotal.ttc
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    .replaceAll(',', ' ')
                }}
                € <span class="bold-text">Total à payer :</span>
                {{ '   ' }}
                {{
                  computedTotal.someApaye
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    .replaceAll(',', ' ')
                }}
                €
              </div>
            </div>
          </div>
          <v-spacer></v-spacer>

          <v-btn text @click="hideModal">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NewLModelRowOblige from '@/models/NewLModelRowOblige';
export default {
  data() {
    return {
      checkAll: false,
      dataOblige: [],
      loaderData: false,
      dialog: false
    };
  },
  computed: {
    ...mapGetters([
      'getAppeliPaiementThTableObligee',
      'formateDateGetters',
      'checkPermission',
      'getUserData'
    ]),
    computedDFields() {
      let fields = [
        {
          value: 'check_all',
          text: '',
          show: true,
          width: '40px !important'
        },
        {
          value: 'lot',
          text: 'Lot',
          show: true
        },
        {
          value: 'client',
          text: 'Client',
          show: true
        },
        {
          value: 'vendeur',
          text: 'Vendeur',
          show: true
        },
        {
          value: 'numero_facture',
          text: 'Numéro de facture',
          show: true
        },
        {
          value: 'ht',
          text: 'Montant HT',
          show: true
        },
        { value: 'tva', text: 'Tva', show: true },
        {
          value: 'ttc',
          text: 'Montant TTC',
          show: true
        },
        {
          value: 'reste',
          text: 'Reste à payer',
          show: true
        },
        {
          value: 'etat',
          text: 'Statut de facture',
          show: true
        },
        {
          value: 'statut_client',
          text: 'Statut client',
          show: true
        },
        {
          value: 'date_paiement',
          text: 'Date de paiement',
          show: true
        },
        {
          value: 'reglement',
          text: 'Reglement',
          show: true
        }
      ];
      return fields.filter(i => i.show == true);
    },
    computedUserData() {
      return this.getUserData?.filiales.length == 0 ? true : false;
    },
    computedCheckedRowsOneClient() {
      return this.computedCheckedRowsOneClientFacture.length ==
        this.dataOblige.filter(i => i.check == true).length &&
        this.computedCheckedRowsOneClientFacture.length > 0
        ? true
        : false;
    },
    computedCheckedRowsOneClientFacture() {
      let tableReglement = [];

      this.dataOblige.forEach(facture => {
        if (
          facture.check == true &&
          facture.reste != '0' &&
          facture.type != 'Facture proforma' &&
          (facture.etat == 'A payer' || facture.etat == 'Payé en partie')
        ) {
          if (tableReglement.length > 0) {
            if (
              facture.client_id == tableReglement[0].client_id &&
              facture.vendeur_id == tableReglement[0].vendeur_id &&
              facture.typeReglement == tableReglement[0].typeReglement
            ) {
              tableReglement.push(facture);
            }
          } else {
            tableReglement.push(facture);
          }
        }
      });

      return tableReglement;
    },
    computedTotal() {
      let someHt = 0;
      let someTtc = 0;
      let someApaye = 0;

      for (let i = 0; i < this.dataOblige.length; i++) {
        if (this.dataOblige[i].check == true) {
          someHt =
            someHt +
              Math.round(
                parseFloat(
                  this.dataOblige[i].total_ht +
                    ''.replace(' ', '').replace(',', '.')
                ) * 100
              ) /
                100 || 0;
          someTtc =
            someTtc +
              Math.round(
                parseFloat(
                  this.dataOblige[i].total_ttc +
                    ''.replace(' ', '').replace(',', '.')
                ) * 100
              ) /
                100 || 0;
          if (
            this.dataOblige[i].type == 'Facture' &&
            (this.dataOblige[i].etat == 'A payer' ||
              this.dataOblige[i].etat == 'Payé en partie' ||
              this.dataOblige[i].etat == 'Payé')
          ) {
            someApaye =
              someApaye +
                Math.round(
                  parseFloat(
                    this.dataOblige[i].reste +
                      ''.replace(' ', '').replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
          }
        }
      }
      return {
        ht: Math.round(someHt * 100) / 100,
        ttc: Math.round(someTtc * 100) / 100,
        someApaye: Math.round(someApaye * 100) / 100
      };
    }
  },
  methods: {
    hideModal() {
      this.dialog = false;
    },
    rowClass(item, type) {
      if (item && item.reste == 0) {
        return 'ligneAlert';
      }
      return 'ligneNormale';
    },
    updateDataInParentComponentReglement(payload) {
      payload.data.forEach(element => {
        payload.response.factures_regles.forEach(fact => {
          var index = element.lot_origin.missing_prime_factures
            .map(e => e.id)
            .indexOf(fact.id);
          var type_fact = 'missing_prime_factures';
          if (index < 0) {
            index = element.lot_origin.prime_factures
              .map(e => e.id)
              .indexOf(fact.id);
            type_fact = 'prime_factures';
          }
          if (index < 0) {
            index = element.lot_origin.surprime_factures
              .map(e => e.id)
              .indexOf(fact.id);
            type_fact = 'surprime_factures';
          }
          if (index < 0) {
            index = element.lot_origin.commission_factures
              .map(e => e.id)
              .indexOf(fact.id);
            type_fact = 'commission_factures';
          }
          if (index < 0) {
            index = element.lot_origin.commission_gestion_factures
              .map(e => e.id)
              .indexOf(fact.id);
            type_fact = 'commission_gestion_factures';
          }
          if (index >= 0) {
            element.lot_origin[type_fact][index].reste = fact.reste;
            element.lot_origin[type_fact][index].reglements = fact.reglements;
            element.lot_origin[type_fact][index].date_echeance =
              fact.date_echeance;
            element.lot_origin[type_fact][index].montant_regle =
              fact.montant_regle;
            element.lot_origin[type_fact][index].date_paiement =
              fact.date_paiement;
            element.lot_origin[type_fact][index].payment_type =
              fact.payment_type;
            element.lot_origin[type_fact][index].etat = fact.etat;
            element.lot_origin[type_fact][index].statut_client =
              fact.statut_client;
          }
        });
      });
    },
    checkAllFunction() {
      this.dataOblige.map(item => {
        item.check = this.checkAll;
      });
    },
    handleClickVueFacture() {
      this.loaderData = true;
      this.dataOblige = [];
      this.dialog = true;
      this.getAppeliPaiementThTableObligee.forEach(filiale => {
        filiale.lots.forEach(lot => {
          //missing prime facture
          if (this.checkPermission('GAPTHACI')) {
            let mission_prime_fact = lot.missing_prime_factures.filter(
              i => i.etat != 'Annulée' && i.belongsTo == true
            );
            mission_prime_fact.forEach(element => {
              this.dataOblige.push(
                NewLModelRowOblige.create({
                  ...element,
                  lot: lot.name,
                  lot_origin: lot
                })
              );
            });
          }
          //prime facture
          if (this.checkPermission('GAPTHACP')) {
            let prime_fact = lot.prime_factures.filter(
              i => i.etat != 'Annulée' && i.belongsTo == true
            );
            prime_fact.forEach(element => {
              this.dataOblige.push(
                NewLModelRowOblige.create({
                  ...element,
                  lot: lot.name,
                  lot_origin: lot
                })
              );
            });
          }
          //surprime facture
          if (this.checkPermission('GAPTHACS')) {
            let surprime_fact = lot.surprime_factures.filter(
              i => i.etat != 'Annulée' && i.belongsTo == true
            );

            surprime_fact.forEach(element => {
              this.dataOblige.push(
                NewLModelRowOblige.create({
                  ...element,
                  lot: lot.name,
                  lot_origin: lot
                })
              );
            });
          }
          if (this.checkPermission('GAPTHACC')) {
            //commision facture
            let commission_fact = lot.commission_factures.filter(
              i => i.etat != 'Annulée' && i.belongsTo == true
            );

            commission_fact.forEach(element => {
              this.dataOblige.push(
                NewLModelRowOblige.create({
                  ...element,
                  lot: lot.name,
                  lot_origin: lot
                })
              );
            });
          }
          if (this.checkPermission('GAPTHACCG')) {
            //commision gestion facture
            let commission_gestion_fact = lot.commission_gestion_factures.filter(
              i => i.etat != 'Annulée' && i.belongsTo == true
            );
            commission_gestion_fact.forEach(element => {
              this.dataOblige.push(
                NewLModelRowOblige.create({
                  ...element,
                  lot: lot.name,
                  lot_origin: lot
                })
              );
            });
          }
          if (this.checkPermission('GAPTHACFCEI')) {
            //commision consolidee_energie_invest

            let consolidee_energie_invest_fact = lot.consolidee_energie_invest_factures.filter(
              i => i.etat != 'Annulée' && i.belongsTo == true
            );
            consolidee_energie_invest_fact.forEach(element => {
              this.dataOblige.push(
                NewLModelRowOblige.create({
                  ...element,
                  lot: lot.name,
                  lot_origin: lot
                })
              );
            });
          }
        });
      });
      this.loaderData = false;
    }
  },
  components: {
    reglement: () => import('@/views/component/reglement/reglement.vue'),
    PopoverReglementFacture: () =>
      import('@/views/component/Facture/PopoverReglementFacture.vue')
  }
};
</script>
<style lang="scss">
.table-dialog {
  .v-data-table__wrapper {
    max-height: calc(100vh - 222px) !important;
    height: calc(100vh - 222px) !important;
  }
  &.show-btn {
    .v-data-table__wrapper {
      max-height: calc(100vh - 283px) !important;
      height: calc(100vh - 283px) !important;
    }
  }
}
.v-dialog.custom-vuetify-dialog-header-vue-oblige.v-dialog--active.v-dialog--persistent.v-dialog--scrollable {
  top: 0px !important;
  position: absolute !important;
  height: 100%;
}
</style>
<style>
.reg-oblige .block-icon {
  padding: 2px 2px;
}
</style>
